import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { isAuthenticatedSelector } from "../redux/userSlice";

function Footer() {
  const { t } = useTranslation();
  const [authenticated, setAuthenticated] = useState(false)
  const {
    email,
  } = useAppSelector(isAuthenticatedSelector)

  useEffect(() => {
    if(!email ) {
      setAuthenticated(false);
    } else if(email) {
      setAuthenticated(true);
    }
  }, [email]);

  return (
    <div className="footer-container">
    <div className="footer">
      <div className="footer-contacts">
        <p className="footer-headers">{t('footer.aboutUs')}</p>
        <p className="footer-links"><NavLink to="/terms-of-usage" className="footer-links">{t('footer.termsOfUse')}</NavLink></p>
        <p className="footer-links"><NavLink to="/privacy-policy" className="footer-links">{t('footer.privacy')}</NavLink></p>
        <p className="footer-links"><NavLink to="/cookies-policy" className="footer-links">{t('footer.cookies')}</NavLink></p>
        <p>{t('footer.email')}</p>
      </div>
      <div className="footer-pages">
        <p className="footer-headers">{t('footer.pages')}</p>
        {!authenticated && <>
        <p className="footer-links"><NavLink to="/" className="footer-links">{t('footer.home')}</NavLink></p>
        <p className="footer-links"><NavLink to="/login" className="footer-links">{t('footer.login')}</NavLink></p>
        <p className="footer-links"><NavLink to="/signup" className="footer-links">{t('footer.register')}</NavLink></p>
        <p className="footer-links"><NavLink to="/market-public" className="footer-links">{t('footer.market')}</NavLink></p>
        </>}
        {authenticated && <>
        <p className="footer-links"><NavLink to="/" className="footer-links">{t('footer.home')}</NavLink></p>
        <p className="footer-links"><NavLink to="/profile" className="footer-links">{t('footer.profile')}</NavLink></p>
        <p className="footer-links"><NavLink to="/market" className="footer-links">{t('footer.market')}</NavLink></p>
        <p className="footer-links"><NavLink to="/send-to-me-categories" className="footer-links">{t('footer.sendToMe')}</NavLink></p>
        <p className="footer-links"><NavLink to="/logout" className="footer-links">{t('footer.logout')}</NavLink></p>
        </>}
      </div>
    </div>
    <div className="text-center p-3 footer-copyright" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
    © 2024 Copyright:  Web Guru Solutions Ltd
    </div>
    </div>
  )
}

export default Footer