import { useState } from "react";


interface PreviewFlashCardProps {
  sideOne: string;
  sideTwo: string;
}

function PreviewFlashCard({ sideOne, sideTwo }: PreviewFlashCardProps) {
  const [side, setSide] = useState<boolean>();

  function handleClick() {
    setSide(!side);
  }
  return (
    <div className={`flash-card ${side ? "side" : ""}`} onClick={handleClick}>
      <div className="front">
        {sideOne}
      </div>
      <div className="back">
        {sideTwo}
      </div>
    </div>
  )
}

export default PreviewFlashCard