import { BASE_URL } from "../common/constants";
import axios from "axios";
import { CategoryType } from "../common/category-model";
import { withTokenCheck } from "./userAxiosServices"

///CATEGORIES SERVICES

const getMyCategories = async (userId: string, aiCreated: boolean, currentPage: number) => {
  const response = axios.get(BASE_URL + `card/category/`, {
    params: { 
      user_id: userId, 
      ai_created: aiCreated ? 'True' : 'False', 
      page: currentPage},
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getMyCategoriesService = withTokenCheck(getMyCategories)

const getAllMyCategories = async (userId: string) => {
  const response = axios.get(BASE_URL + `card/category-all/`, {
    params: { user_id: userId},
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getAllMyCategoriesService = withTokenCheck(getAllMyCategories)

const getCategoryDetails = async (categoryId: string) => {
  const response = axios.get(BASE_URL + `card/category/${categoryId}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getCategoryDetailsService = withTokenCheck(getCategoryDetails)

const createCategory = async ({categoryName, userId, categoryType}: Pick<CategoryType, "categoryName" | "userId" | "categoryType">) => {
  const body = {
    name: categoryName,
    user: userId,
    type_of_cards: categoryType
  }
  const response = axios.post(BASE_URL + `card/category/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const createCategoryService = withTokenCheck(createCategory)

const editCategory = async ({
  categoryId, 
  categoryName, 
  userId,
  categoryType
}: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "categoryType">) => {
  const body = {
    name: categoryName,
    user: userId,
    type_of_cards: categoryType
  }
  const response = axios.put(BASE_URL + `card/category/${categoryId}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const editCategoryService = withTokenCheck(editCategory)

const deleteCategory = async (categoryId: string) => {
  const response = axios.delete(BASE_URL + `card/category/${categoryId}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const deleteCategoryService = withTokenCheck(deleteCategory)