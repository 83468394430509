import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CardPicture, CardPictureCreateEdit, CardPictureVote, GetCardTextPaginatedRequest, GetCardTextSearchParams } from '../common/card-model';

import {
  createCardPicturesService,
  deleteCardPicturesService,
  editCardPicturesService,
  getCardsPicturesByCategoryService,
  getCardsPicturesTagsService,
  getCardsPicturesWithFiltersService,
  getCardsPicturesDetailsService,
  getCardsPictureByCategoryPaginatedService
} from '../services/cardPictureAxiosServices';
import { 
  guestCardPictureService, 
  guestTypingCardPictureService, 
  incorrectGuestCardPictureService, 
  incorrectGuestTypingCardPictureService 
} from '../services/cardPicturesVoteAxiosServices';
import { RootState } from './store';

interface CardsPicturesStateType {
  allCardsPictures: CardPicture[];
  counts: number | null;
  pagesCardPicture: number;
  filteredCardsPictures: CardPicture[];
  cardPictureDetails: CardPicture;
  categoryPicturesTags: string[];
  getCardPictureLoading: boolean;
  fetchAllCardsPicturesLoading: boolean;
  createCardPicturesLoading: boolean;
  deleteCardPicturesLoading: boolean;
  getCardPicturesTagsLoading: boolean
  deleteCardPicturesError: string | undefined;
  createCardPicturesError: string | undefined;
  updateCardPicturesError: string | undefined;
  getCardPicturesTagsError: string | undefined;
  getCardPicturesError: string | undefined;
  updateCardPicturesLoading: boolean;
  loginRequired: boolean;
  randomId: number;
  usedIds: number [];
  guestOrNot: number;
}

const initialState: CardsPicturesStateType = {
  allCardsPictures: [],
  counts: null,
  pagesCardPicture: 0,
  filteredCardsPictures: [],
  cardPictureDetails: {
    id: "",
    sideOne: "",
    sideTwo: "",
    categoryId: "",
    tag: "",
    createdAt: "",
    updateAt: "",
    guessesScore: 0,
    incorrectGuessesScore: 0,
    guessesScoreTyping: 0,
    incorrectGuessesScoreTyping: 0
  },
  categoryPicturesTags: [],
  getCardPictureLoading: false,
  fetchAllCardsPicturesLoading: false,
  createCardPicturesLoading: false,
  deleteCardPicturesLoading: false,
  updateCardPicturesLoading: false,
  getCardPicturesTagsLoading: false,
  deleteCardPicturesError: undefined,
  createCardPicturesError: undefined,
  updateCardPicturesError: undefined,
  getCardPicturesTagsError: undefined,
  getCardPicturesError: undefined,
  loginRequired: false,
  randomId: 0,
  usedIds: [],
  guestOrNot: 0
}

export const getCardPicturesTags = createAsyncThunk('cardPicture/getCardPicturesTags', async (categoryId: string) => {
  let response
  try {
    response = await getCardsPicturesTagsService(categoryId)
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const getAllCardsPicturesByFilters = createAsyncThunk('cardPicture/getAllCardsPicturesByFilters', async (
  { categoryId, quantity, tags, type }: GetCardTextSearchParams
) => {
  let response
  try {
    response = await getCardsPicturesWithFiltersService({ categoryId, quantity, tags, type })
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const getAllCardsPicturesByCategory = createAsyncThunk('cardPicture/getAllCardsPicturesByCategory', async (
  categoryId: string
) => {
  let response
  try {
    response = await getCardsPicturesByCategoryService(categoryId)
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const getAllCardsPictureByCategoryPaginated = createAsyncThunk('cardText/getAllCardsTextByCategoryPaginated', async ({
  categoryId,
  currentPage,
  tags,
  searchQuery
}: GetCardTextPaginatedRequest) => {
  let response
  try {
    response = await getCardsPictureByCategoryPaginatedService(categoryId, currentPage, tags, searchQuery)
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const createNewCardPictures = createAsyncThunk('cardPicture/createNewCardPictures', async ({
  sideOne,
  sideTwo,
  tag,
  categoryId
}: Pick<CardPictureCreateEdit, 'sideOne' | 'sideTwo' | 'tag' | 'categoryId'>) => {
  let response;
  try {
    response = await createCardPicturesService({ sideOne, sideTwo, tag, categoryId })
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const deleteCardPicturesById = createAsyncThunk('cardPicture/deleteCardText', async (cardId: string) => {
  try {
    await deleteCardPicturesService(cardId)
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const editCardPicturesById = createAsyncThunk('cardPicture/editCardText', async ({
  sideOne,
  sideTwo,
  categoryId,
  tag,
  id
}: Pick<CardPictureCreateEdit, "sideOne" | "sideTwo" | "categoryId" | "id" | "tag">) => {
  try {
    await editCardPicturesService({ sideOne, sideTwo, categoryId, tag, id })
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const getCardPicturesById = createAsyncThunk('cardPicture/getCardPicturesById', async (cardId: string) => {
  let response;
  try {
    response = await getCardsPicturesDetailsService(cardId)
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const guestCardPictures = createAsyncThunk('cardPicture/guestCardPictures', async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
  guessesScore
}: Pick<CardPictureVote, "id" | "categoryId" | "sideOne" | "sideTwo" | "guessesScore">) => {
  let response;
  try {
    response = await guestCardPictureService({
      id,
      categoryId,
      sideOne,
      sideTwo,
      guessesScore: guessesScore + 1
    })
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const notGuestCardPictures = createAsyncThunk('cardPicture/notGuestCardPictures', async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
  incorrectGuessesScore
}: Pick<CardPictureVote, "id" | "categoryId" | "sideOne" | "sideTwo" | "incorrectGuessesScore">) => {
  let response;
  try {
    response = await incorrectGuestCardPictureService({
      id,
      categoryId,
      sideOne,
      sideTwo,
      incorrectGuessesScore: incorrectGuessesScore  + 1
    })
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const guestTypingCardPictures = createAsyncThunk('cardPicture/guestTypingCardPictures', async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
  guessesScoreTyping
}: Pick<CardPictureVote, "id" | "categoryId" | "sideOne" | "sideTwo" | "guessesScoreTyping">) => {
  let response;
  try {
    response = await guestTypingCardPictureService({
      id,
      categoryId,
      sideOne,
      sideTwo,
      guessesScoreTyping: guessesScoreTyping + 1
    })
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const notGuestTypingCardPictures = createAsyncThunk('cardPicture/notGuestTypingCardPictures', async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
  incorrectGuessesScoreTyping
}: Pick<CardPictureVote, "id" | "categoryId" | "sideOne" | "sideTwo" | "incorrectGuessesScoreTyping">) => {
  let response;
  try {
    response = await incorrectGuestTypingCardPictureService({
      id,
      categoryId,
      sideOne,
      sideTwo,
      incorrectGuessesScoreTyping: incorrectGuessesScoreTyping + 1
    })
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})


export const cardsPicturesSlice = createSlice({
  name: 'cardPicture',
  initialState,
  reducers: {
    cleanCreateError: (state: CardsPicturesStateType) => {
      state.createCardPicturesError = undefined
      state.getCardPicturesError = undefined
      state.updateCardPicturesError = undefined
    },
    cleanDeleteError: (state: CardsPicturesStateType) => {
      state.deleteCardPicturesError = undefined
    },
    cleanGetCardTextDetails: (state: CardsPicturesStateType) => {
      state.getCardPicturesError = undefined
    },
    cleanEditErrorCardPicture: (state: CardsPicturesStateType) => {
      state.updateCardPicturesError = undefined
      state.createCardPicturesError = undefined
    },
    cleanCardPictureDetails: (state: CardsPicturesStateType) => {
      state.cardPictureDetails.categoryId = ""
      state.cardPictureDetails.createdAt = ""
      state.cardPictureDetails.id = ""
      state.cardPictureDetails.guessesScore = 0
      state.cardPictureDetails.guessesScoreTyping = 0
      state.cardPictureDetails.incorrectGuessesScore = 0
      state.cardPictureDetails.incorrectGuessesScoreTyping = 0
      state.cardPictureDetails.sideOne = ""
      state.cardPictureDetails.sideTwo = ""
      state.cardPictureDetails.tag = ""
      state.cardPictureDetails.updateAt = ""
    },
    cleanFilteredCardsPictures: (state: CardsPicturesStateType) => {
      state.filteredCardsPictures = []
    },
    cleanAllCardsPictures: (state: CardsPicturesStateType) => {
      state.allCardsPictures = []
      state.counts = null
      state.pagesCardPicture = 0
    },
    cleanCardsPicturesTags: (state: CardsPicturesStateType) => {
      state.categoryPicturesTags = []
    },
    guessAnswerCorrect: (state: CardsPicturesStateType) => {
      state.guestOrNot = 1
    },
    guessAnswerNotCorrect: (state: CardsPicturesStateType) => {
      state.guestOrNot = -1
    },
    guessAnswerClearCorrect: (state: CardsPicturesStateType) => {
      state.guestOrNot = 0
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardPicturesTags.pending, (state: CardsPicturesStateType) => {
        state.getCardPicturesTagsError = undefined
        state.getCardPicturesTagsLoading = true
      })
      .addCase(getCardPicturesTags.fulfilled, (state: CardsPicturesStateType, action) => {
        state.getCardPicturesTagsLoading = false
        state.categoryPicturesTags = action.payload
      })
      .addCase(getCardPicturesTags.rejected, (state: CardsPicturesStateType, action) => {
        state.getCardPicturesTagsLoading = false
        state.getCardPicturesTagsError = action.error.message
      }),
      builder
        .addCase(getAllCardsPicturesByCategory.pending, (state: CardsPicturesStateType) => {
          state.getCardPicturesError = undefined
          state.fetchAllCardsPicturesLoading = true
        })
        .addCase(getAllCardsPicturesByCategory.fulfilled, (state: CardsPicturesStateType, action) => {
          state.fetchAllCardsPicturesLoading = false
          const allCardsPictures: CardPicture[] = []
          action.payload.forEach((card: any) => {
            const cartPictureObject = {
              id: card.id,
              sideOne: card.first_side_url,
              sideTwo: card.second_side,
              categoryId: card.category,
              createdAt: card.created_at,
              updateAt: card.updated_at,
              guessesScore: card.guesses_score,
              incorrectGuessesScore: card.incorrect_guesses_score,
              guessesScoreTyping: card.guesses_score_typing,
              incorrectGuessesScoreTyping: card.incorrect_guesses_score_typing,
              tag: card.tag
            }
            allCardsPictures.push(cartPictureObject)
          })
          state.allCardsPictures = allCardsPictures
        })
        .addCase(getAllCardsPicturesByCategory.rejected, (state: CardsPicturesStateType, action) => {
          state.fetchAllCardsPicturesLoading = false
          state.getCardPicturesError = action.error.message
        }),
      builder
        .addCase(getAllCardsPictureByCategoryPaginated.pending, (state: CardsPicturesStateType) => {
          state.getCardPicturesError = undefined
          state.fetchAllCardsPicturesLoading = true
        })
        .addCase(getAllCardsPictureByCategoryPaginated.fulfilled, (state: CardsPicturesStateType, action) => {
          state.fetchAllCardsPicturesLoading = false
          state.counts = action.payload.count
          const pages = Math.ceil(action.payload.count / 20)
          state.pagesCardPicture = action.payload.count / 20 < 0 ? 2 : pages
          const allCardsPictures: CardPicture[] = []
          action.payload.results.forEach((card: any) => {
            const cartPictureObject = {
              id: card.id,
              sideOne: card.first_side_url,
              sideTwo: card.second_side,
              categoryId: card.category,
              createdAt: card.created_at,
              updateAt: card.updated_at,
              guessesScore: card.guesses_score,
              incorrectGuessesScore: card.incorrect_guesses_score,
              guessesScoreTyping: card.guesses_score_typing,
              incorrectGuessesScoreTyping: card.incorrect_guesses_score_typing,
              tag: card.tag
            }
            allCardsPictures.push(cartPictureObject)
          })
          state.allCardsPictures = allCardsPictures
        })
        .addCase(getAllCardsPictureByCategoryPaginated.rejected, (state: CardsPicturesStateType, action) => {
          state.fetchAllCardsPicturesLoading = false
          state.getCardPicturesError = action.error.message
        }),
      builder
        .addCase(getAllCardsPicturesByFilters.pending, (state: CardsPicturesStateType) => {
          state.getCardPicturesError = undefined
          state.fetchAllCardsPicturesLoading = true
        })
        .addCase(getAllCardsPicturesByFilters.fulfilled, (state: CardsPicturesStateType, action) => {
          state.fetchAllCardsPicturesLoading = false
          const allCardsPictures: CardPicture[] = []
          action.payload.forEach((card: any) => {
            const cartTextObject = {
              id: card.id,
              sideOne: card.first_side_url,
              sideTwo: card.second_side,
              categoryId: card.category,
              createdAt: card.created_at,
              updateAt: card.updated_at,
              guessesScore: card.guesses_score,
              incorrectGuessesScore: card.incorrect_guesses_score,
              guessesScoreTyping: card.guesses_score_typing,
              incorrectGuessesScoreTyping: card.incorrect_guesses_score_typing,
              tag: card.tag
            }
            allCardsPictures.push(cartTextObject)
          })
          state.filteredCardsPictures = allCardsPictures
          if (state.usedIds.length >= state.filteredCardsPictures.length) {
            state.usedIds = []
          }
          let newId;
          do {
            newId = Math.floor(Math.random() * state.filteredCardsPictures.length);
          } while (state.usedIds.includes(newId));
          state.usedIds.push(newId);
          state.randomId = newId;
        })
        .addCase(getAllCardsPicturesByFilters.rejected, (state: CardsPicturesStateType, action) => {
          state.fetchAllCardsPicturesLoading = false
          state.getCardPicturesError = action.error.message
        }),
      builder
        .addCase(createNewCardPictures.pending, (state: CardsPicturesStateType) => {
          state.createCardPicturesError = undefined
          state.getCardPicturesError = undefined
          state.updateCardPicturesError = undefined
          state.createCardPicturesLoading = true
        })
        .addCase(createNewCardPictures.fulfilled, (state: CardsPicturesStateType) => {
          state.createCardPicturesLoading = false
        })
        .addCase(createNewCardPictures.rejected, (state: CardsPicturesStateType, action) => {
          state.createCardPicturesLoading = false
          state.createCardPicturesError = action.error.message
        }),
      builder
        .addCase(deleteCardPicturesById.pending, (state: CardsPicturesStateType) => {
          state.deleteCardPicturesError = ""
          state.deleteCardPicturesLoading = true
        })
        .addCase(deleteCardPicturesById.fulfilled, (state: CardsPicturesStateType) => {
          state.deleteCardPicturesLoading = false
        })
        .addCase(deleteCardPicturesById.rejected, (state: CardsPicturesStateType, action) => {
          state.deleteCardPicturesLoading = false
          state.deleteCardPicturesError = action.error.message
        }),
      builder
        .addCase(getCardPicturesById.pending, (state: CardsPicturesStateType) => {
          state.getCardPicturesError = undefined
          state.getCardPictureLoading = true
        })
        .addCase(getCardPicturesById.fulfilled, (state: CardsPicturesStateType, action) => {
          state.getCardPictureLoading = false
          state.cardPictureDetails.id = action.payload.id
          state.cardPictureDetails.sideOne = action.payload.first_side
          state.cardPictureDetails.sideTwo = action.payload.second_side
          state.cardPictureDetails.categoryId = action.payload.category
          state.cardPictureDetails.tag = action.payload.tag
          state.cardPictureDetails.createdAt = action.payload.created_at
          state.cardPictureDetails.updateAt = action.payload.updated_at
          state.cardPictureDetails.guessesScore = action.payload.guesses_score
          state.cardPictureDetails.incorrectGuessesScore = action.payload.incorrect_guesses_score
          state.cardPictureDetails.guessesScoreTyping = action.payload.guesses_score_typing
          state.cardPictureDetails.incorrectGuessesScore = action.payload.incorrect_guesses_score_typing
        })
        .addCase(getCardPicturesById.rejected, (state: CardsPicturesStateType, action) => {
          state.getCardPictureLoading = false
          state.getCardPicturesError = action.error.message
        })
    builder
      .addCase(editCardPicturesById.pending, (state: CardsPicturesStateType) => {
        state.updateCardPicturesError = undefined
        state.updateCardPicturesLoading = true
      })
      .addCase(editCardPicturesById.fulfilled, (state: CardsPicturesStateType) => {
        state.updateCardPicturesLoading = false
      })
      .addCase(editCardPicturesById.rejected, (state: CardsPicturesStateType, action) => {
        state.updateCardPicturesLoading = false
        state.updateCardPicturesError = action.error.message
      })
    builder
      .addCase(guestCardPictures.pending, (state: CardsPicturesStateType) => {
        state.updateCardPicturesError = undefined
        state.updateCardPicturesLoading = true
      })
      .addCase(guestCardPictures.fulfilled, (state: CardsPicturesStateType) => {
        state.updateCardPicturesLoading = false
      })
      .addCase(guestCardPictures.rejected, (state: CardsPicturesStateType, action) => {
        state.updateCardPicturesLoading = false
        state.updateCardPicturesError = action.error.message
      })
    builder
      .addCase(notGuestCardPictures.pending, (state: CardsPicturesStateType) => {
        state.updateCardPicturesError = undefined
        state.updateCardPicturesLoading = true
      })
      .addCase(notGuestCardPictures.fulfilled, (state: CardsPicturesStateType) => {
        state.updateCardPicturesLoading = false
      })
      .addCase(notGuestCardPictures.rejected, (state: CardsPicturesStateType, action) => {
        state.updateCardPicturesLoading = false
        state.updateCardPicturesError = action.error.message
      })
    builder
      .addCase(guestTypingCardPictures.pending, (state: CardsPicturesStateType) => {
        state.updateCardPicturesError = undefined
        state.updateCardPicturesLoading = true
      })
      .addCase(guestTypingCardPictures.fulfilled, (state: CardsPicturesStateType) => {
        state.updateCardPicturesLoading = false
      })
      .addCase(guestTypingCardPictures.rejected, (state: CardsPicturesStateType, action) => {
        state.updateCardPicturesLoading = false
        state.updateCardPicturesError = action.error.message
      })
    builder
      .addCase(notGuestTypingCardPictures.pending, (state: CardsPicturesStateType) => {
        state.updateCardPicturesError = undefined
        state.updateCardPicturesLoading = true
      })
      .addCase(notGuestTypingCardPictures.fulfilled, (state: CardsPicturesStateType) => {
        state.updateCardPicturesLoading = false
      })
      .addCase(notGuestTypingCardPictures.rejected, (state: CardsPicturesStateType, action) => {
        state.updateCardPicturesLoading = false
        state.updateCardPicturesError = action.error.message
      })
  }
})

export const actions = cardsPicturesSlice.actions;

export const allCardsPicturesSelector = (state: RootState) => state[cardsPicturesSlice.name];
