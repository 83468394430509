import { BASE_URL } from "../common/constants";
import axios from "axios";
import { withTokenCheck } from './userAxiosServices'
import { aiGenerateCardText } from "../common/card-model";

const generateCardsText = async ({topic, sideOne, sideTwo, level, audienceAge, language, cardNumber }: aiGenerateCardText) => {
	const body = { 
		topic: topic,
		sideOne:sideOne,
		sideTwo:sideTwo,
		level: level,
		audienceAge: audienceAge,
		language: language,
		cardNumber: cardNumber
	}
	const response = await axios.post(BASE_URL + `card/generate-flashcards/`, body, {
		
		headers: {
			Authorization: `JWT ${localStorage.getItem('access')}`,
		}
	});
	return response;
}

export const generateCardsTextService = withTokenCheck(generateCardsText)

export interface aiCreateCardTextProps {
	categoryName: string;
	flashcards: {
		sideOne: string;
		sideTwo: string;
		tag: string;
	}[];
}

const aiCreateCardText = async ({categoryName, flashcards}: aiCreateCardTextProps) => {
	const body = { 
		category_name: categoryName,
		flashcards: flashcards
	}
	const response = await axios.post(BASE_URL + `card/create-ai-flashcards/`, body, {
		
		headers: {
			Authorization: `JWT ${localStorage.getItem('access')}`,
		}
	});
	return response;
}

export const aiCreateCardTextService = withTokenCheck(aiCreateCardText)